<template>
	<h1>Buttons</h1>
	<div class="text-block light bump-b-30">
		<ActionBack />
		<ActionClose :inline="true" />
		<ActionCluster :minimal="true" />
		<ActionCollect />
		<ActionCollect :collected="true" />
		<ActionLink />
		<ActionLogo />
		<ActionMenu />
		<ActionRemove />
		<ActionRemove forceState="add" />
		<ActionEndorse :entity="{}" entityType="" />
		<ActionSelect />
		<ActionSelect :on="true" />
		<ActionSelect :color="true" :on="true" />
		<ActionSearch />
	</div>
	<div class="text-block light bump-b-30">
		<ActionAny icon="lock" :to="{ name: 'About' }" @click="onClick('Link')" />
		<ActionAny icon="lock" :soft="true" @click="onClick('Click action')" />
		<div class="inverse">
			<ActionAny icon="lock" :inverse="true" @click="onClick('Inverse')" />
		</div>
		<ActionAny icon="lock" :small="true" @click="onClick('small')" />
		<ActionAny icon="lock" :tiny="true" @click="onClick('tiny')" />
	</div>
	<div class="text-block dark bump-b-30">
		<ActionAny icon="lock" :dark="true" :to="{ name: 'About' }" @click="onClick('Link')" />
		<ActionAny icon="lock" :dark="true" :soft="true" @click="onClick('Click action')" />
		<div class="inverse">
			<ActionAny icon="lock" :inverse="true" @click="onClick('Inverse')" />
		</div>
		<ActionAny icon="lock" :dark="true" :small="true" @click="onClick('small')" />
		<ActionAny icon="lock" :dark="true" :tiny="true" @click="onClick('tiny')" />
	</div>

	<div class="text-block dark bump-b-30">
		<ActionBack :dark="true" />
		<ActionClose :dark="true" :inline="true" />
		<ActionCluster :dark="true" :minimal="true" />
		<ActionCollect :dark="true" />
		<ActionCollect :dark="true" :collected="true" />
		<ActionLink :dark="true" />
		<ActionLogo :dark="true" />
		<ActionMenu :dark="true" />
		<ActionRemove :dark="true" />
		<ActionRemove :dark="true" forceState="add" />
		<ActionEndorse :dark="true" :entity="{}" entityType="" />
		<ActionSelect :dark="true" />
		<ActionSelect :dark="true" :on="true" />
		<ActionSelect :color="true" :on="true" />
		<ActionSearch :dark="true" />
	</div>

	<ButtonExtension :dark="true" />
</template>

<script>
import ActionLogo from '@/components/ActionLogo'
import ActionMenu from '@/components/ActionMenu'
import ActionLink from '@/components/ActionLink'
import ActionSearch from '@/components/ActionSearch'
import ActionBack from '@/components/ActionBack'
import ActionClose from '@/components/ActionClose'
import ActionCollect from '@/components/ActionCollect'
import ActionRemove from '@/components/ActionRemove'
import ActionSelect from '@/components/ActionSelect'
import ActionCluster from '@/components/ActionCluster'
import ButtonExtension from '@/components/ButtonExtension'
import ActionEndorse from '@/components/ActionEndorse'
import ActionAny from '@/components/ActionAny'
import { injectMetaData } from '@/use/MetaData'

export default {
	name: 'DocComponentsButtons',
	components: {
		ActionLogo,
		ActionMenu,
		ActionLink,
		ActionSearch,
		ActionBack,
		ActionClose,
		ActionCollect,
		ActionRemove,
		ActionSelect,
		ActionCluster,
		ButtonExtension,
		ActionEndorse,
		ActionAny,
	},
	setup() {
		injectMetaData({
			title: 'Doc: Buttons',
		})
	},
	methods: {
		onClick(msg) {
			alert(msg)
		},
	},
}
</script>

<style scoped lang="scss">
.text-block {
	display: flex;
	flex-direction: row;
	padding: 0.2rem;
	background: $white;
	flex-wrap: wrap;
}
.text-block.dark {
	background: $black;
	padding: 0.2rem;
	border-radius: $br;
}
.text-block.light > div:nth-child(odd),
.text-block.light > a:nth-child(odd) {
	background: rgba(0, 0, 0, 0.05);
}
.text-block.light > div:nth-child(even),
.text-block.light > a:nth-child(even) {
	background: rgba(0, 0, 0, 0.08);
}
.text-block.dark > div:nth-child(odd),
.text-block.dark > a:nth-child(odd) {
	background: rgba($white, 0.03);
}
.text-block.dark > div:nth-child(even),
.text-block.dark > a:nth-child(even) {
	background: rgba($white, 0.06);
}
.inverse {
	mix-blend-mode: difference;
}
</style>
