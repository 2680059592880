<template>
	<div class="act-link" :class="{ dark }" @click="onClick">
		<BaseIcon name="link" />
	</div>
</template>

<script>
// Components
import BaseIcon from '@/components/BaseIcon'
import { share } from '@/use/General'
import { baseUrl } from '@/use/BaseUrls'

export default {
	name: 'ActionLink',
	components: { BaseIcon },
	props: {
		dark: Boolean,
		entityType: String,
		entity: Object,
		path: String,
	},
	methods: {
		onClick() {
			share({
				entityType: this.entityType,
				entity: this.entity,
				url: baseUrl + this.path,
			})
		},
	},
}
</script>

<style scoped lang="scss">
.act-link {
	width: 0.5rem;
	height: 0.5rem;
	padding: 0.15rem;
	cursor: pointer;
}

// Dark version
.act-link.dark:deep() .icn {
	fill: $white-75;
}

@media (hover: hover) {
	.act-link:hover:deep() .icn {
		fill: $primary;
	}
}
</style>
