<template>
	<div
		@click="delaySetEndorse"
		class="btn-endorse"
		:class="{ processing, approved: endorseLevel == 1, endorsed: endorseLevel == 2, dark }"
	>
		<BaseIcon name="star" />
		<div class="bg"></div>
		<div class="loader"></div>
	</div>
</template>

<script>
// Stores
import { useApiStore } from '@/stores/ApiStore'

// Components
import BaseIcon from '@/components/BaseIcon'

// Internal
import { handleApiError } from '@/use/Helpers'

export default {
	name: 'ActionEndorse',
	components: {
		BaseIcon,
	},
	props: {
		entityType: {
			type: String,
			required: true,
		},
		entity: {
			type: Object,
			required: true,
		},
		dark: Boolean,
	},
	setup() {
		const apiStore = useApiStore()
		const generalApi = apiStore.loadApi('general')
		return { generalApi }
	},
	data() {
		return {
			prevEndorseLevel: 0,
			endorseLevel: 0,
			processing: false,
			setEndorseTimeout: false,
		}
	},
	mounted() {
		if (this.entity) {
			if (this.entityType == 'artwork') {
				this.endorseLevel = this.entity.promoted ? 2 : 0
			} else {
				this.endorseLevel = this.entity.endorse || 0
			}
		}
	},
	beforeUnmount() {
		clearTimeout(this.setEndorseTimeout)
	},
	methods: {
		// This allows you to double click
		// without sending to server
		async delaySetEndorse() {
			this.processing = true

			// Instant feedback
			this.prevEndorseLevel = this.endorseLevel
			if (this.entityType == 'artwork') {
				// 0/2 (Boolean)
				this.endorseLevel = this.endorseLevel ? 0 : 2
			} else {
				// 0/1/2
				this.endorseLevel = (this.endorseLevel + 1) % 3
			}

			// Call server after short interval
			// allowing us to double click.
			clearTimeout(this.setEndorseTimeout)
			this.setEndorseTimeout = setTimeout(this.setEndorse, 500)
		},
		async setEndorse() {
			// Update endorseLevel
			const { status, data, statusText } = await this.generalApi.setEntityEndorse(
				this.entityType,
				this.entity.id,
				this.endorseLevel
			)

			// Reset endorseLevel on error
			if (status != 200) {
				const action =
					(this.endorseLevel === 0 ? 'un-endorsing' : this.endorseLevel == 1 ? 'approving' : 'endorsing') +
					' this ' +
					this.entityType
				handleApiError({ status, data, statusText, action })
				//
				this.endorseLevel = this.prevEndorseLevel
			}

			this.processing = false
		},
	},
}
</script>

<style scoped lang="scss">
.btn-endorse {
	height: 0.5rem;
	width: 0.5rem;
	padding: 0.15rem;
	border: none;
	border-radius: $br;
	vertical-align: top;
	cursor: pointer;
	position: relative;
}
.btn-endorse:deep() .icn {
	position: relative;
	z-index: 1;
}

// Background & loader circle
.btn-endorse .bg,
.btn-endorse .loader {
	width: 0.3rem;
	height: 0.3rem;
	border-radius: 0.15rem;
	position: absolute;
	top: 0.1rem;
	left: 0.1rem;
	z-index: 0;
}
.btn-endorse .loader {
	border: solid 0.02rem $black-15;
	clip-path: $cp-load-30;
	display: none;
}

// Processing state
.btn-endorse.processing .loader {
	animation: rotate 1s linear infinite;
	display: block;
}

// Approved state
.btn-endorse:not(.processing).approved .bg {
	border: solid 0.02rem $good;
}
.btn-endorse.approved:deep() .icn {
	fill: $good;
}

// Endorsed state
.btn-endorse.endorsed .bg {
	background: $gold;
}
.btn-endorse.endorsed:deep() .icn {
	fill: $white;
}

/**
 * Dark version
 */
.btn-endorse.dark:not(.approved):deep() .icn {
	fill: $white;
}
.btn-endorse.dark:not(.endorsed) .loader {
	border-color: $white-30;
}

@media (hover: hover) {
	.btn-endorse:not(.processing):hover:deep() .icn {
		fill: $primary;
	}
	.btn-endorse.approved:not(.processing):hover:deep() .icn {
		fill: $gold;
	}
	.btn-endorse.endorsed:not(.processing):hover .bg {
		background: $black-10;
	}
	.btn-endorse.endorsed:not(.processing):hover:deep() .icn {
		fill: $white;
	}

	// Dark version
	.btn-endorse.endorsed:not(.processing):hover .bg {
		background: $white-30;
	}
}
</style>
